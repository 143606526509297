<template>
  <form novalidate @submit.prevent="validate" class="personal-information-view">
    <Title> Личные данные </Title>

    <PersonalInformation
      ref="personalInformation"
      @changeBirthday="changeBirthday"
    />
    <PassportInformation
      ref="passportInformation"
      class="personal-information-view__passport"
    />

    <div class="personal-information-view__actions">
      <Button type="submit"> Продолжить </Button>
      <Button back @click.native="back"> Назад </Button>
    </div>
  </form>
</template>

<script>
import "./personal-information-view.scss";

import PersonalInformation from "@index/components/application/personal-information/PersonalInformation";
import PassportInformation from "@index/components/application/passport-information/PassportInformation";
import Title from "@index/components/gui/title/Title";
import Button from "@index/components/gui/button/Button";

import store from "@index/store";

import setMask from "@index/helpers/string/setMask";
import openRoute from "@index/helpers/router/openRoute";

export default {
  name: "PersonalInformationView",
  methods: {
    validate() {
      this.$refs.personalInformation.validate();
      const isPersonalValid = this.$refs.personalInformation.isValid;

      const passportScroll = !isPersonalValid;
      this.$refs.passportInformation.validate(passportScroll);

      this.$refs.passportInformation.isValid &&
        isPersonalValid &&
        this.submit();
    },
    async submit() {
      const { next_step, noValid } = await this.$store.dispatch(
        "application/send",
        {
          contactData: {
            ...this.$refs.personalInformation.form,
            ...this.$refs.passportInformation.form.contactData,
          },
          passportData: {
            ...this.$refs.passportInformation.form.passportData,
            passportissuecode: setMask(
              this.$refs.passportInformation.form.passportData
                .passportissuecode,
              "###-###"
            ),
          },
        }
      );

      if (noValid && Object.keys(noValid)) {
        const personalInfoError = this.$refs.personalInformation.formErrors;

        personalInfoError.firstname =
          (noValid.firstname === false && "Невалидное значение") || "";
        personalInfoError.lastname =
          (noValid.lastname === false && "Невалидное значение") || "";
        personalInfoError.patronymic =
          (noValid.patronymic === false && "Невалидное значение") || "";
        personalInfoError.birthday =
          (noValid.birthday === false && "Невалидное значение") || "";
        personalInfoError.gender =
          (noValid.gender === false && "Невалидное значение") || "";

        const passportInfoError =
          this.$refs.passportInformation.formErrors.passportData;

        passportInfoError.passportissuecode =
          (noValid.passportissuecode === false && "Невалидное значение") || "";
        passportInfoError.passportnumber =
          (noValid.passportnumber === false && "Невалидное значение") || "";
        passportInfoError.passportseries =
          (noValid.passportseries === false && "Невалидное значение") || "";
        passportInfoError.passportissuer =
          (noValid.passportissuer === false && "Невалидное значение") || "";
        passportInfoError.passportissuedate =
          (noValid.passportissuedate === false && "Невалидное значение") || "";

        return;
      }

      openRoute(next_step);
    },
    back() {
      this.$router.push({ name: "ContactInfo" });
    },
    changeBirthday(v) {
      this.$refs.passportInformation.form.contactData.birthday = v;
    },
  },
  components: {
    PersonalInformation,
    PassportInformation,
    Title,
    Button,
  },
  beforeRouteEnter(to, from, next) {
    const {
      contactData: { phone },
    } = store.getters["application/user"];

    if (phone) {
      next();
    } else {
      store.commit("application/load", false);
      next({ name: "ContactInfo" });
    }
  },
};
</script>
